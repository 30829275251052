angular.module('sq.user.settings', ['sq.user.auth'])
.controller('SettingsCtrl', SettingsCtrl);

function SettingsCtrl($scope, $routeParams, $log, Auth, minPasswordLength, $window, $timeout, $location) {
  this.errors = {};

  this.minPasswordLength = minPasswordLength || 8;
  this.action = $routeParams.action || null;
  this.resetToken = $routeParams.token || null;
  this.auth = Auth; //exposes user property, without using watcher.

  console.info('minPasswordLength is', minPasswordLength);
  // Submitted shouldn't be false when completed is true; actions are stacked
  this.actions = {
    sendResetLink: {
      submitted: false,
      message: false,
      completed: false,
      error: false,
    },
    resetPassword: {
      submitted: false,
      message: false,
      completed: false,
      error: false,
    }
  };

  const _complete = (action, success, message, err) => {
    $timeout(() => {
      if(success) {
        this.actions[action]['completed'] = true;
      } else {
        this.actions[action]['submitted'] = false;
      }

      this.actions[action]['message'] = message;
      this.actions[action]['error'] = err;
    }, 16);
  };

  const _clear = (action) => {
    this.showNextAction = false;

    Object.keys(this.actions[action]).forEach( key => {
      this.actions[action][key] = false;
    });
  };

  this.sendResetLink = (email) => {
    if(!email) {
      _complete('sendResetLink', false, null, {required: true});
      return;
    }

    _clear('sendResetLink');
    this.actions['sendResetLink'].submitted = true;
    Auth.sendResetLink(email).then(() => {
      _complete('sendResetLink', true, 'Sent!');
    }, (response) => {
      if (response.status >= 500) {
        _complete('sendResetLink', false, null, {serverError: true});
      } 
      // don't tell people their email was incorrect, security concern
      _complete('sendResetLink', true, 'Sent!');
    });
  };

  this.resetPassword = (newPassword) => {if(!newPassword) {
      _complete('resetPassword', false, null, {required: true});
      return;
    }

    _clear('resetPassword');
    this.actions['resetPassword'].submitted = true;
    Auth.resetPassword(this.resetToken, newPassword).then(() => {
      _complete('resetPassword', true, 'Done!');
    }, (response) => {
      if (response.status >= 500) {
        _complete('resetPassword', false, null, {serverError: true});
      } else {
        _complete('resetPassword', false, null, {tokenExpired: true});
      }
    });
  };

  this.goTo = (loc) => {
    $location.path(loc);
  };
}